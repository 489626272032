<template>
  <section class="add-patient wh100">
    <main-modal />

    <pacient-header v-if="data.id" />

    <header v-else class="pacient-header">
      <span class="breadcrumb">home > pacient nou</span>

      <div class="flex flex-between">
        <figure class="avatar flex align-center">
          <img class="opa4" src="../assets/user.svg" alt="user avatar" />
          <span class="uppercase opa5 inline-block mleft10px"
            >{{ data.nume }} {{ data.prenume }}</span
          >
        </figure>
      </div>
    </header>

    <section class="form-wrapper">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)" class="relative">
          <validation-provider rules="required" v-slot="{ errors }">
            <input
              type="text"
              cy="add-pacient-nume"
              @keydown.enter.prevent
              class="block w100 capitalize"
              :class="{ 'invalid-input': errors[0] }"
              maxlength="40"
              @keypress="validateField($event)"
              v-model="data.nume"
              autocomplete="as9dasd11"
              placeholder="nume"
            />
          </validation-provider>

          <validation-provider rules="required" v-slot="{ errors }">
            <input
              type="text"
              class="block w100 capitalize"
              cy="add-pacient-prenume"
              @keydown.enter.prevent
              :class="{ 'invalid-input': errors[0] }"
              @keypress="validateField($event)"
              v-model="data.prenume"
              maxlength="40"
              autocomplete="as9dasd112"
              placeholder="prenume"
            />
          </validation-provider>

          <input
            type="text"
            class="block w100"
            ref="flags"
            data-test="add-pacient-telefon"
            :class="{ 'invalid-input': noPhone }"
            @keyup="setValue($event, 'telefon')"
            onfocus="this.autocomplete='no'"
            onmouseover="this.autocomplete='no'"
            placeholder="telefon"
          />

          <validation-provider rules="email" v-slot="{ errors }">
            <input
              type="text"
              @keydown.enter.prevent
              class="block w100"
              :class="{ 'invalid-input': errors[0] }"
              v-model="data.email"
              autocomplete="asdasd-9as09dasd1112"
              placeholder="email"
            />
          </validation-provider>

          <!--  CNP -->
          <div class="cnp-fields flex flex-between">
            <validation-provider
              class="flex-max mright8px"
              rules="min:13"
              v-slot="{ errors }"
            >
              <input
                type="text"
                class="block w100"
                @keydown.enter.prevent
                :class="{ 'invalid-input': errors[0] }"
                maxlength="13"
                v-model="data.cnp"
                @keypress="validateField($event, 'number')"
                placeholder="cnp"
              />
            </validation-provider>

            <!-- ID Strain -->
            <validation-provider v-slot="{ errors }">
              <input
                type="text"
                class="block w100"
                @keydown.enter.prevent
                :class="{ 'invalid-input': errors[0] }"
                v-model="data.idExtern"
                placeholder="ID extern"
              />
            </validation-provider>
          </div>

          <!-- DATEPICKER / VARSTA -->
          <div class="flex flex-between">
            <div class="calendar relative w100 mright8px">
              <datepicker
                v-model="birthdate"
                :monday-first="true"
                :language="ro"
                :disabled-dates="disabledDates"
                :format="customFormatter"
                placeholder="data nasterii"
                name="uniquename"
              ></datepicker>
            </div>

            <validation-provider v-slot="{ errors }">
              <input
                type="text"
                data-test="add-pacient-varsta"
                v-model="data.varsta"
                @keydown.enter.prevent
                :class="{ 'invalid-input': errors[0] }"
                placeholder="varsta"
              />
            </validation-provider>
          </div>

          <validation-provider v-slot="{ errors }">
            <input
              type="text"
              @keydown.enter.prevent
              class="block w100 capitalize"
              :class="{ 'invalid-input': errors[0] }"
              v-model="data.strada"
              data-test="add-pacient-strada"
              placeholder="strada"
            />
          </validation-provider>

          <!--  JUDET -->
          <div class="flex flex-between drop-downs">
            <drop-down
              class="drop-down-judet"
              :data="ddJudet"
              :submitted="submitted"
              @selected="setDDvalue"
            />

            <!--            <drop-down :data="ddOras"-->
            <!--                       :submitted="submitted"-->
            <!--                       @selected="setDDvalue"/>-->

            <input
              type="text"
              class="oras"
              v-model="data.oras"
              data-test="oras"
              placeholder="oras/sat"
            />

            <drop-down
              :data="ddTara"
              :submitted="submitted"
              @selected="setDDvalue"
            />
          </div>

          <!-- MEDIC CURANT -->

          <drop-down
            :data="ddMedic"
            :submitted="submitted"
            @selected="setDDvalue"
          />

          <!-- TEHNICIAN -->

          <validation-provider rules="" v-slot="{ errors }">
            <input
              type="text"
              @keydown.enter.prevent
              class="block w100 capitalize"
              :class="{ 'invalid-input': errors[0] }"
              v-model="data.tehnician"
              placeholder="tehnician"
            />
          </validation-provider>

          <div class="interval-igiena flex align-center">
            <h2>INTERVAL IGIENIZARE</h2>

            <select v-model="data.interval_igienizare">
              <option v-for="(item, index) in intervalIgienizare" :key="index">
                {{ item }}
              </option>
            </select>
            <span>zile</span>
          </div>

          <h2>ANTECEDENTE MEDICALE</h2>

          <div class="check-boxes">
            <div class="flex align-center">
              <check-box ref="cardiace" :payload="cardiace" />
              <input
                v-show="cardiace.showInput"
                v-model="data.antecedente.cardiace.text"
                @keydown.enter.prevent
                type="text"
                placeholder="afectiuni"
              />
            </div>
            <div class="flex align-center">
              <check-box ref="hepatice" :payload="hepatice" />
              <input
                v-show="hepatice.showInput"
                v-model="data.antecedente.hepatice.text"
                @keydown.enter.prevent
                type="text"
                placeholder="afectiuni"
              />
            </div>
            <div class="flex align-center">
              <check-box ref="epilepsie" :payload="epilepsie" />
              <input
                v-show="epilepsie.showInput"
                v-model="data.antecedente.epilepsie.text"
                @keydown.enter.prevent
                type="text"
                placeholder="afectiuni"
              />
            </div>
            <div class="flex align-center">
              <check-box ref="renale" :payload="renale" />
              <input
                v-show="renale.showInput"
                v-model="data.antecedente.renale.text"
                @keydown.enter.prevent
                type="text"
                placeholder="afectiuni"
              />
            </div>
            <div class="flex align-center">
              <check-box ref="diabet" :payload="diabet" />
              <input
                v-show="diabet.showInput"
                v-model="data.antecedente.diabet.text"
                @keydown.enter.prevent
                type="text"
                placeholder="afectiuni"
              />
            </div>
            <div class="flex align-center">
              <check-box ref="sanguine" :payload="sanguine" />
              <input
                v-show="sanguine.showInput"
                v-model="data.antecedente.sanguine.text"
                @keydown.enter.prevent
                type="text"
                placeholder="afectiuni"
              />
            </div>
            <div class="flex align-center">
              <check-box ref="alergii" :payload="alergii" />
              <input
                v-show="alergii.showInput"
                v-model="data.antecedente.alergii.text"
                @keydown.enter.prevent
                type="text"
                placeholder="afectiuni"
              />
            </div>
          </div>

          <!-- SUBMIT -->
          <div class="flex flex-between mtop30px">
            <button
              v-if="isAdmin && isEditPacient"
              data-test="delete-pacient"
              class="delete-button pointer"
              @click="deletePacient"
            >
              <i v-html="svg.delete"></i>
            </button>
            <button
              cy="save-pacient"
              class="submit flex-center m-left-auto submit-active green-btn-hover"
              @click="submitBtn"
            >
              <i v-html="svg.save"></i>
            </button>
          </div>
        </form>
      </ValidationObserver>
    </section>
  </section>
</template>

<script>
  import IMask from 'imask';
  import intlTelInput from 'intl-tel-input';
  import { ro } from 'vuejs-datepicker/dist/locale';
  import moment from 'moment';
  import CheckBox from '../components/checkbox';
  import { mapState } from 'vuex';
  import 'moment/locale/ro';
  import { EventBus } from '../main';
  import { daysToMs, msToDays } from '../store/utils';
  moment.locale('ro');

  export default {
    name: 'AddPatient',

    data () {
      return {
        intervalIgienizare: ['NA', 90, 180, 360],
        data: {
          nume: '',
          prenume: '',
          telefon: '',
          phoneCountry: {},
          email: '',
          cnp: '',
          idExtern: '',
          judet: '',
          oras: '',
          tara: 'Romania',
          medic: '',
          tehnician: '',
          varsta: '',
          birthdate: '',
          interval_igienizare: 180,
          antecedente: {
            cardiace: {
              val: false,
              text: ''
            },
            hepatice: {
              val: false,
              text: ''
            },
            epilepsie: {
              val: false,
              text: ''
            },
            renale: {
              val: false,
              text: ''
            },
            diabet: {
              val: false,
              text: ''
            },
            sanguine: {
              val: false,
              text: ''
            },
            alergii: {
              val: false,
              text: ''
            }
          }
        },
        birthdate: undefined,
        submitted: false,
        phoneMask: undefined,
        ro,
        noPhone: false,
        disabledDates: {
          from: new Date()
        },
        ddJudet: {
          name: 'judet',
          placeholder: 'județ',
          ddData: 'judete',
          default: '',
          disabled: false,
          clbk: (item) => {
            this.$store.commit('FILTER_ORASE_BY_JUDET', item);
            this.ddOras.disabled = false;
            this.ddOras.default = undefined;
            this.data.oras = undefined;
            EventBus.$emit('reset-dd-model', { name: 'oras' });
          },
          /* blur dropdown input */
          blur: () => {
            this.disableLocalitate();
          }
        },
        ddOras: {
          name: 'oras',
          placeholder: 'localitate',
          ddData: 'orase',
          disabled: !this.ddJudet,
          default: ''
        },
        ddTara: {
          name: 'tara',
          placeholder: 'țară',
          ddData: 'tari',
          default: 'Romania',
          validation: true,
          clbk: (tara) => {
            const condition = tara !== 'Romania';
            if (condition) {
              this.data.judet = undefined;
              this.data.oras = undefined;
              this.ddJudet.default = undefined;
              EventBus.$emit('reset-dd-model', { name: 'judet' });
              EventBus.$emit('reset-dd-model', { name: 'oras' });
              this.ddJudet.disabled = true;
              this.ddOras.disabled = !this.data.judet;
            } else {
              this.ddJudet.disabled = false;
              this.ddOras.disabled = !this.data.judet;
            }
          }
        },
        ddMedic: {
          name: 'medic',
          placeholder: 'medic curant',
          ddData: 'medici',
          default: ''
        },
        cardiace: {
          name: 'cardiace',
          label: 'CARDIACE',
          red: true,
          showInput: false,
          clbk: (val) => {
            this.data.antecedente.cardiace.val = val;
            !val && (this.data.antecedente.cardiace.text = '');
            this.cardiace.showInput = !this.cardiace.showInput;
          }
        },
        hepatice: {
          name: 'hepatice',
          label: 'HEPATICE',
          red: true,
          showInput: false,
          clbk: (val) => {
            this.data.antecedente.hepatice.val = val;
            !val && (this.data.antecedente.hepatice.text = '');
            this.hepatice.showInput = !this.hepatice.showInput;
          }
        },
        epilepsie: {
          name: 'epilepsie',
          label: 'EPILEPSIE',
          red: true,
          showInput: false,
          clbk: (val) => {
            this.data.antecedente.epilepsie.val = val;
            !val && (this.data.antecedente.epilepsie.text = '');
            this.epilepsie.showInput = !this.epilepsie.showInput;
          }
        },
        renale: {
          name: 'renale',
          label: 'RENALE',
          red: true,
          showInput: false,
          clbk: (val) => {
            this.data.antecedente.renale.val = val;
            !val && (this.data.antecedente.renale.text = '');
            this.renale.showInput = !this.renale.showInput;
          }
        },
        diabet: {
          name: 'diabet',
          label: 'DIABET',
          red: true,
          showInput: false,
          clbk: (val) => {
            this.data.antecedente.diabet.val = val;
            !val && (this.data.antecedente.diabet.text = '');
            this.diabet.showInput = !this.diabet.showInput;
          }
        },
        sanguine: {
          name: 'sanguine',
          label: 'SANGUINE',
          red: true,
          showInput: false,
          clbk: (val) => {
            this.data.antecedente.sanguine.val = val;
            !val && (this.data.antecedente.sanguine.text = '');
            this.sanguine.showInput = !this.sanguine.showInput;
          }
        },
        alergii: {
          name: 'alergii',
          label: 'ALERGII',
          red: true,
          showInput: false,
          clbk: (val) => {
            this.data.antecedente.alergii.val = val;
            !val && (this.data.antecedente.alergii.text = '');
            this.alergii.showInput = !this.alergii.showInput;
          }
        }
      };
    },

    created () {
      const id = this.$router.currentRoute.params.id;
      const clbk = (obj) => {
        this.data = obj;
        this.data.interval_igienizare = msToDays(this.data.interval_igienizare);
        this.$refs.flags.value = obj.telefon;
        this.ddJudet.default = obj.judet;
        obj.judet && this.$store.commit('FILTER_ORASE_BY_JUDET', obj.judet);
        this.ddOras.default = obj.oras;
        this.ddTara.default = obj.tara;
        this.ddMedic.default = obj.medic;
        obj.birthdate && (this.birthdate = `${obj.birthdate} (data nașterii)`);
        Object.keys(obj.antecedente).forEach((key) => {
          this.data.antecedente[key] = obj.antecedente[key];
          obj.antecedente[key].val && this.$refs[key].toggle();
        });
      };
      id && this.$store.dispatch('get_patient', { id, clbk });
      this.$store.dispatch('get_medici');
    },

    mounted () {
      this.setIntlTelInput();
      EventBus.$on('confirmation-yes', () => {
        this.confirmDeletePacient();
      });
    },

    computed: {
      ...mapState({
        isEditPacient: 'isEditPacient',
        svg: 'svg',
        user: 'user'
      }),

      isAdmin () {
        return this.user.rol.includes('admin');
      }
    },

    methods: {
      disableLocalitate () {
        EventBus.$emit('reset-dd-model', { name: 'oras' });
        this.data.oras = undefined;
        this.ddOras.disabled = true;
      },
      setIntlTelInput (iso2) {
        // const phone = document.querySelector('#flags');
        const phone = this.$refs.flags;
        this.telInput = intlTelInput(phone, {
          // separateDialCode: true,
          utilsScript: require('intl-tel-input/build/js/utils'),
          initialCountry: iso2 || 'RO',
          autoPlaceholder: 'aggressive',
          customPlaceholder: (
            selectedCountryPlaceholder,
            selectedCountryData
          ) => {
            !this.data.phoneCountry && (this.data.phoneCountry = {});
            this.data.phoneCountry.iso2 = selectedCountryData.iso2;
            this.data.phoneCountry.dialCode = selectedCountryData.dialCode;
            const mask = selectedCountryPlaceholder
              .replace(/[0-9]/g, 0)
              .toString();
            if (this.mask !== mask) {
              this.setMask(mask);
            }
            return mask;
          }
        });
      },
      setMask (mask) {
        this.phoneMask && this.phoneMask.destroy();
        // this.phoneMask = new IMask(document.getElementById('flags'), { mask })
        // this.phoneMask = new IMask(this.$refs.flags, { mask })
      },
      setValue (ev, field) {
        this.data[field] = ev.currentTarget.value;
      },
      validateField (ev, rule) {
        let re;
        const val = ev.key;
        switch (rule) {
          case 'alpha':
            re = /[- a-zA-Z]/;
            break;
          case 'number':
            re = /[0-9]/;
            break;
        }
        if (rule) {
          !re.test(val) && ev.preventDefault();
        }
      },
      getAge (date) {
        this.$nextTick(() => {
          const ageDifMs = Date.now() - date.getTime();
          const ageDate = new Date(ageDifMs);
          this.data.varsta = `${Math.abs(
            ageDate.getUTCFullYear() - 1970
          )} (vârsta)`;
        });
      },
      customFormatter (date) {
        this.data.date = date.toLocaleDateString('en-US');
        this.getAge(date);
        return moment(date).format('Do MMM YYYY') + ' (data nașterii)';
      },
      setDDvalue ({ name, value }) {
        this.data[name] = value;
      },
      submitBtn () {
        // this.noPhone = !this.data.telefon
      },
      submit () {
        /* disabled la cererea clientului - no phone no mandatory */
        // if (this.data.telefon) {
        //   this.data.interval_igienizare = daysToMs(this.data.interval_igienizare)
        //   this.$store.dispatch('save_pacient', this.data)
        // }
        this.data.interval_igienizare = daysToMs(this.data.interval_igienizare);
        this.$store.dispatch('handle_pacient', this.data);
      },
      deletePacient (ev) {
        ev.preventDefault();
        this.$store.commit('SET_KEY_VALUE', {
          key: 'confirmationMsg',
          value: 'Sunteți sigur că doriți să ștergeți acest pacient?'
        });
        this.$store.commit('SET_KEY_VALUE', {
          key: 'confirmationSubject',
          value: 'pacient'
        });
        this.$store.commit('SET_MODAL', {
          name: 'confirmationModal',
          val: true
        });
      },
      confirmDeletePacient () {
        this.$store.dispatch('delete_pacient');
      }
    },
    components: {
      CheckBox
    },
    watch: {
      data: {
        handler: function (newVal, oldVal) {
          !newVal.phoneCountry && (this.data.phoneCountry = {});
          if (
            newVal.phoneCountry &&
            newVal.phoneCountry.iso2 !== 'ro' &&
            !oldVal.nume
          ) {
            this.telInput && this.telInput.destroy();
            setTimeout(() => {
              this.setIntlTelInput(newVal.phoneCountry.iso2);
            }, 0);
          }
        },
        deep: true
      }
    },
    destroyed () {
      this.telInput && this.telInput.destroy();
      this.phoneMask && this.phoneMask.destroy();
      EventBus.$off('confirmation-yes');
    }
  };
</script>

<style lang="less">
  @import "../assets/styles/var";
  @import "../assets/styles/mixin";
  @import "../../node_modules/intl-tel-input/build/css/intlTelInput.css";

  .add-patient {
    padding-top: 24px;
    display: flex;
    flex-direction: column;

    .breadcrumb + div.flex.flex-between {
      margin: 16px 0;
    }

    input.oras {
      width: 100%;
      padding-left: 24px;
      margin-right: 8px;
    }

    .form-wrapper {
      padding: 6px 10px 100px 0;
      overflow-y: scroll;
      flex: 0 1 auto;
    }
    .form-wrapper::-webkit-scrollbar {
      /*display: none;*/
      width: 12px; /* Remove scrollbar space */
      background: #e6e7e8; /* Optional: just make scrollbar invisible */
    }
    /* Optional: show position indicator in red */
    .form-wrapper::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: @medium-grey;
    }

    .avatar img {
      height: 41px;
    }

    form {
      input {
        padding-left: 82px;
        margin-bottom: 16px;
      }

      .iti {
        width: 100%;
        margin-bottom: 16px;

        input {
          padding-left: 82px;
        }
      }

      .iti__flag-container {
        margin-left: 30px;
        font-size: 1.3rem;
      }

      .drop-downs .drop-down {
        width: 100%;
        &:not(:last-child) {
          margin-right: 8px;
        }
      }

      .drop-down input {
        padding-left: 30px;
      }

      .drop-down-judet input {
        padding-left: 82px;
      }

      input[placeholder="medic curant"] {
        padding-left: 82px;
      }

      .submit {
        .circle(65px);
        outline: none;
        border: none;
        background-color: @main-green;
        transition: background-color 0.1s ease-in-out;
      }

      h2 {
        padding: 16px 0;
        font-weight: 400;
        font-size: 1.6rem;
        color: rgba(0, 0, 0, 0.5);
      }

      .check-boxes {
        & > div {
          height: 40px;
          margin-bottom: 10px;
        }
        input {
          width: 100%;
          padding-left: 24px;
          margin-left: 16px;
          margin-bottom: 0 !important;
        }
        label {
          width: 100px;
          font-size: 1.4rem;
          transform: translateY(1px);
        }
      }
    }

    .delete-button {
      .circle(65px);
      border: none;
      background-color: @red;
      transition: background-color 0.2s ease-in-out;
      &:hover {
        background-color: darken(red, 20%, relative);
      }
    }

    .interval-igiena {
      font-size: 2rem;
      color: rgba(0, 0, 0, 0.5);
    }

    select {
      margin: 0 6px 0 16px;
      padding: 6px;
      width: 80px;
      border-color: lightgrey;
      border-radius: 4px;
      font-size: 1.6rem;
      font-family: Comfortaa, sans-serif;
    }
  }
</style>
