<template>
  <section
    class="check-box flex align-center pointer"
    :class="
      isActive && (payload.red ? 'active-checkbox-red' : 'active-checkbox')
    "
    @click="toggle"
  >
    <span></span>

    <label class="pointer">
      {{ payload.label }}
    </label>
  </section>
</template>

<script>
  export default {
    name: 'check-box',
    props: {
      payload: {
        type: Object,
        required: true
      },
      value: {}
    },

    data () {
      return {
        isActive: false
      };
    },

    mounted () {
      this.value && (this.isActive = this.value);
    },

    methods: {
      toggle () {
        this.isActive = !this.isActive;
        this.payload.clbk && this.payload.clbk(this.isActive);
      }
    }
  };
</script>

<style lang="less">
  @import "../assets/styles/var";
  @import "../assets/styles/mixin";
  .check-box {
    span {
      .square(24px);
      display: inline-block;
      border-radius: 4px;
      background-color: lightgrey;
    }
    label {
      padding-left: 12px;
      font-size: 1.6rem;
    }
  }
  .active-checkbox {
    span {
      background-color: @main-green;
    }
    label {
      color: @main-green;
    }
  }
  .active-checkbox-red {
    span {
      background-color: @red;
    }
    label {
      color: @red;
    }
  }
</style>
